<template>
  <div>
    <div class="d-flex justify-content-between align-items-baseline">
      <h2>{{timeTitle}}</h2>

      <div class="d-flex justify-content-end">
        <button @click="goBack" class="btn btn-primary">Tillbaka</button>
      </div>
    </div>

    <number-field
      api="updateTestimonyTime"
      :editable="true"
      :model="timeModel"
      :timeType="timeType"
      :value.sync="time"
      field="time"
      db_field="time"
      placeholder="Ange tid i minuter"
      type="number"
      label="Ange tid i minuter"
      :submitWithButton="true"
      @goBack="goBack"
      :removable="true"
    ></number-field>

  </div>
</template>

<script>
import BojAPI from '@/api/boj'
import NumberField from '@/components/fields/NumberField.vue'

export default {
  components: {
    NumberField
  },
  props: {
   timeModel: Object,
   refreshTime: Function
  },
  data() {
    return {
      time: null,
      timeType: {}
    }
  },
  watch: {
    timeModel: {
      handler () {
        this.time = this.currentSavedTimeType;
      },
      deep: false
    },
  },
  beforeRouteEnter (to, from, next) {
    BojAPI.getTestimonyTime(to.params.id)
    .then((response) => {
      next((vm) => {
        vm.$emit('update:timeModel', response.data.data)
        vm.setTimeType();
      });
    })
    .catch((error) => {})
  },
  computed: {
    timeTitle() {
      return this.timeType !== null ? this.timeType.label : ''
    },
    timeTypeId() {
      return this.$route.params.timeTypeId
    },
    currentSavedTimeType() {
      const savedTime = this.timeModel.testimony_time_types.find((type) => type.id == this.timeType.id);

      if (savedTime) {
        return savedTime.pivot.time
      }

      return null
    }
  },
  methods: {
    goBack() {
      this.$Progress.start();

      this.refreshTime()
      .then(() => {
        this.$Progress.finish();
        // updating.dismiss();
        this.$router.go(-1)
      })
    },
    setTimeType() {
      this.timeType =  this.$store.getters.options.testimony_time_types.find(item => item.id == this.timeTypeId)
    }
  }
}
</script>

<style>

</style>

<template>
  <div>

    <div class="d-flex justify-content-between align-items-baseline">
      <h2>Lägg till person</h2>

      <div class="d-flex justify-content-end">
        <button @click="goBack" class="btn btn-primary">Tillbaka</button>
      </div>
    </div>

    <AddNewPeople
      :initialPerson="person"
      :initialTestimony="initialTestimony"
      :answerAddPerson="answerAddPerson"
      @finish="setFormAsFinish"
      @setCurrentStep="setCurrentStep"
    />

  </div>
</template>

<script>
import BojAPI from '@/api/boj'
import AddNewPeople from '@/components/forms/AddNewPeople.vue'

export default {
  components: {
    AddNewPeople
  },
  props: {
    initialPerson: Object,
    initialTestimony: Object,
    refreshTestimony: Function
  },
  data() {
    return {
      person: {},
      answerAddPerson: true
    }
  },
  beforeRouteEnter (to, from, next) {
    BojAPI.getPerson(to.params.personId)
    .then((response) => {
      next((vm) => {
        vm.setData(response.data)
      });
    })
    .catch((error) => {})
  },
  methods: {
    goBack() {
      this.setFormAsFinish();
    },
    setCurrentStep(step) {
      if (step == 1) {
        this.$Progress.start()

        BojAPI.addTestimonyPerson(this.$route.params.id)
        .then((response) => {
          const personId = response.data.id
          this.$router.push({ name: 'Lägg till ny person', params: { personId: personId } })
          this.setData(response.data)
        })
        .catch((error) => {
          console.log('there was an error addTestimonyPerson');
        })

        return;
      }
    },
    setFormAsFinish() {
      this.$Progress.start()

      this.refreshTestimony()
      .then((response) => {
        this.$Progress.finish()
        this.$router.push({ name: 'Uppdatera mål', params: { id: this.$route.params.id } })
      })
    },
    setData(payload) {
      this.person = payload;
    }
  }
}
</script>

<style>

</style>

<template>
  <div>

    <div class="d-flex justify-content-between align-items-baseline">
      <h2>Ändra målet</h2>

      <div class="d-flex justify-content-end">
        <button @click="goBack" class="btn btn-primary">Tillbaka</button>
      </div>
    </div>

    <div class="widget position-relative">
      <div v-if="isRefreshTestimony" class="loader d-flex justify-content-center align-items-center">
        <font-awesome-icon icon="circle-notch" spin size="3x"></font-awesome-icon>
      </div>
      <div class="widget-body bt-1 pr-0 pl-3">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 br-1">

              <select-field
                :fullWidth="true"
                class="mb-3 text-black"
                api="updateTestimonyField"
                :clearable="false"
                :model="testimony"
                :value.sync="testimony.testimony_type_id"
                :options="testimonyTypes"
                db_field="testimony_type_id"
                field="testimony_type"
                label="Typ av mål"
                :optionLabel="$label()"
                :infoBox="''"
                @fieldSaved="updateTestimony"
                :editable="testimony.is_editable"
              ></select-field>

              <select-field
                :fullWidth="true"
                class="mb-3 text-black"
                v-if="isCrimeOrCivilCase('Brottmål')"
                api="updateTestimonyField"
                :clearable="false"
                :model="testimony"
                :value.sync="testimony.crime_id"
                :options="crimes"
                db_field="crime_id"
                field="crime"
                label="Brottstyp"
                :optionLabel="$label()"
                :infoBox="''"
                :editable="testimony.is_editable"
              ></select-field>

              <select-field
                :fullWidth="true"
                class="mb-3 text-black"
                v-if="isCrimeOrCivilCase('Civilmål')"
                api="updateTestimonyField"
                :clearable="false"
                :model="testimony"
                :value.sync="testimony.testimony_other_type_id"
                :options="testimonyOtherTypes"
                db_field="testimony_other_type_id"
                field="testimony_other_type"
                label="Civilmål"
                :optionLabel="$label()"
                :infoBox="''"
                :editable="testimony.is_editable"
              ></select-field>

              <select-field
                :fullWidth="true"
                class="mb-3 text-black"
                api="updateTestimonyField"
                :clearable="false"
                :model="testimony"
                :value.sync="testimony.external_case_id"
                :options="externalCases"
                db_field="external_case_id"
                field="external_case_id"
                label="Externt mål"
                :optionLabel="$label()"
                :infoBox="''"
                :editable="testimony.is_editable"
              ></select-field>

              <select-field
                :fullWidth="true"
                class="mb-3 text-black"
                api="updateTestimonyField"
                :clearable="false"
                :model="testimony"
                :value.sync="testimony.testimony_security_room_id"
                :options="securityRooms"
                db_field="testimony_security_room_id"
                field="testimony_security_room"
                label="Säkerhetssal"
                :optionLabel="$label()"
                :infoBox="''"
                :editable="testimony.is_editable"
              ></select-field>

              <select-field
                :fullWidth="true"
                class="mb-3 text-black"
                api="updateTestimonyField"
                :clearable="true"
                :model="testimony"
                :value.sync="testimony.special_events_id"
                :options="specialEvents"
                db_field="special_events_id"
                field="special_events"
                label="Särskilda händelser"
                :optionLabel="$label()"
                :infoBox="''"
                :editable="testimony.is_editable"
              ></select-field>

              <select-field
                :fullWidth="true"
                class="mb-3 text-black"
                api="updateTestimonyField"
                :clearable="true"
                :model="testimony"
                :value.sync="testimony.crime_relation_id"
                :options="crimeRelations"
                db_field="crime_relation_id"
                field="crime_relation"
                label="Särskilda omständigheter"
                :optionLabel="$label()"
                :infoBox="''"
                :editable="testimony.is_editable"
              ></select-field>

              <!-- <text-field
                :fullWidth="true"
                class="mb-3 text-black"
                api="updateTestimonyField"
                :model="testimony"
                :value.sync="testimony.case_number"
                field="case_number"
                db_field="case_number"
                label=""
                placeholder="Målnummer"
                type="number"
                :infoBox="''"
              ></text-field> -->
                <date-picker v-if="testimony.is_editable"
                  v-model="caseDate"
                  :model="testimony"
                >
                </date-picker>
                <div v-else>
                   <label class="col-sm-12 col-form-label text-black">
                    Rättegångsdatum
                  </label>
                  <div class="form-control disabled " disabled readonly type="text" placeholder="Datum" style="color: rgba(0, 0, 0, 0.6)">
                    {{ caseDate.startDate }}
                  </div>
                </div>
              
            </div>
            <!-- <div class="col-4 br-1"> -->
              <!-- <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.contact-information') }}</h5> -->
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import BojAPI from '@/api/boj'
import SelectField from '@/components/fields/SelectField.vue';
import TextField from '@/components/fields/TextField.vue';
import AddNewTestimonial from '@/components/forms/AddNewTestimonial.vue'
import DatePicker from '@/components/fields/DatePicker.vue';


export default {
  components: {
    AddNewTestimonial,
    SelectField,
    TextField,
    DatePicker
  },
  props: {
    initialTestimony: Object,
    answerAddPerson: Boolean,
    refreshTestimony: Function
  },
  data() {
    return {
      testimony: {is_editable: true},
      testimonyTypes: [],
      testimonyOtherTypes: [],
      crimes: [],
      crimeRelations: [],
      specialEvents: [],
      externalCases: [],
      securityRooms: [],
      isLoading: false,
      isLoadingOptions: false,
      isRefreshTestimony: false,
      caseDate: {}
    }
  },
  watch: {
    initialTestimony(newVal, oldVal) {
      this.setData(newVal)
    },
  },
  mounted() {
    this.setStoreData()

    if (this.initialTestimony !== undefined) {
      this.setData(this.initialTestimony)
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.crimeFieldEmpty) {
      this.setFormAsFinish().then(() => {
        next()
      });
    } else {
      const selectedCaseType = this.testimonyTypes.find(type => type.id == this.testimony.testimony_type.id);

      this.$confirm(
        {
        message: 'Du måste välja ' + selectedCaseType.label.toLowerCase() + ' innan du går härifrån.',
        button: {
          yes: 'OK',
        }
      })

      next(false);
    }
  },
  computed: {
    crimeFieldEmpty() {
      if (this.testimony.is_editable && !this.testimony.crime_id && !this.testimony.testimony_other_type_id) {
        return false;
      }

      return true;
    }
  },
  methods: {
    goBack() {
      if (!this.crimeFieldEmpty) {
        const selectedCaseType = this.testimonyTypes.find(type => type.id == this.testimony.testimony_type.id);

        this.$confirm(
          {
          message: 'Du måste välja ' + selectedCaseType.label.toLowerCase() + ' innan du går härifrån.',
          button: {
            yes: 'OK',
          }
      })
      } else {
         this.$router.push({ name: 'Uppdatera mål', params: { id: this.$route.params.id } })
      }
    },
    setCurrentStep(step) {
      if (step == 1) {
        BojAPI.addTestimonyPerson(this.$route.params.id)
        .then((response) => {
          const personId = response.data.id
          this.$router.push({ name: 'Lägg till ny person', params: { personId: personId } })
        })
        .catch((error) => {
          console.log('there was an error');
          this.currentStep = 0;
        })

        return;
      }
    },
    isCrimeOrCivilCase(type) {
      if (!this.testimony.testimony_type || this.isLoadingOptions) return false;

      let selectedType = this.testimonyTypes.find(type => type.id == this.testimony.testimony_type.id);

      if (selectedType && selectedType.label == type) {
        return true;
      }

      return false
    },
    updateTestimony() {
      this.isRefreshTestimony = true;

      this.refreshTestimony()
      .then(() => {
        this.isRefreshTestimony = false;
      })
    },
    setFormAsFinish() {
      this.$Progress.start()

      const updatingId = this.$toast.success('Uppdaterar...', {
        position: 'top-center',
      });

      return this.refreshTestimony()
      .then(() => {
        this.$Progress.finish();
        this.$toast.dismiss(updatingId);
      })
    },
    setData(payload) {
      this.testimony = payload;
      this.caseDate = {
        startDate: payload.case_date,
        endDate: payload.case_date,
      }
    },
    setStoreData() {
      this.testimonyTypes = this.$store.getters.options.testimony_types;
      this.testimonyOtherTypes = this.$store.getters.options.testimony_other_types;
      this.crimes = this.$store.getters.options.crime;
      this.crimeRelations = this.$store.getters.options.crime_relation;
      this.specialEvents = this.$store.getters.options.special_event;
      this.externalCases = this.$store.getters.options.external_cases;
      this.securityRooms = this.$store.getters.options.testimony_security_room;
    }
  }
}
</script>

<style>

</style>

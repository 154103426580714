<template>

<date-range-picker
  opens="right"
  v-model="caseDate"
  :auto-apply="false"
  date-util="moment"
  :time-picker="true"
  :locale-data="locale"
  :ranges="ranges"
  :show-dropdowns="true"
  :single-date-picker="true"
  ref="picker"
  style="color: black;"
  @update="setDate"
>

  <template #input="picker">
    <label class="col-sm-12 col-form-label text-black">
      Rättegångsdatum
    </label>
    <div class="form-control bg-white" disabled readonly type="text" placeholder="Datum" style="color: rgba(0, 0, 0, 0.6)">
      {{ caseDate.startDate | date }}
      <div v-if="!caseDate.startDate">-</div>
    </div>

  </template>
</date-range-picker>

</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import BojAPI from '@/api/boj'

export default {
  components: {
    DateRangePicker
  },
  props: {
    value: {
      type: Object
    },
    model: {
      type: Object
    }
  },
  data() {
    return {
      caseDate: {
        startDate: '',
        endDate: '',
      },
      locale: {
        direction: 'ltr',
        format: 'mm-dd-yyyy',
        separator: ' - ',
        applyLabel: 'Välj',
        cancelLabel: 'Avbryt',
        fromLabel: 'Från',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sön','Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'],
        monthNames: ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
        firstDay: 1,
        todayLabel:'Idag'
      },
    }
  },
  watch: {
    value: {
      handler(val) {
        this.caseDate = val;
      },
      deep: true
    }
  },
  filters: {
    date (value) {
      if (!value)
        return ''
      return moment(value, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD, HH:mm")
    }
  },
  computed: {
    ranges() {

        return {
          Idag: [moment().toDate(), moment().toDate()],
          Imorgon: [
              moment().add(1, "days").toDate(),
              moment().add(1, "days").toDate()
          ],
          Igår: [
              moment().subtract(1, "days").toDate(),
              moment().subtract(1, "days").toDate()
          ]
        }
    },
  },
  methods: {
    setDate(value) {
      const post = {
        field: 'case_date',
        ids: moment(value.startDate, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm")
      }

      this.caseDate.startDate = moment(value.startDate, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm")
      this.caseDate.endDate = moment(value.endDate, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm")

      this.$emit('input', value)

      BojAPI['updateTestimonyField'](this.model, post)
      .then((response) => {
        this.$toast.success('Ändringar sparade', {
          position: 'top-center',
        });
      })
      .catch((error) => {
        this.$toast.error('Något gick fel', {
          position: 'top-center',
        });
      });
    }
  }
}
</script>

<style>

</style>
